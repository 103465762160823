<template>
  <div id="app">
    <Header />
    <div class="container">
      <!-- <Calculator msg="Taxes TPS et TVQ en 2020 " /> -->
      <!-- Component changes when currentTabComponent changes -->
      <component
        style="background-color: #fafafa;"
        v-bind:is="currentComponent"
      >
      </component>
    </div>
    <div id="contact">
        <Contact />
    </div>
    <Footer />
  </div>
</template>

<script>
import Calculator from "./components/Calculator";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import Contact from "./components/Contact.vue";

export default {
  name: "app",
  metaInfo: {
    title: "Calculatrices de taxes"
  },
  methods: {
    //onToggleCollapse(collapsed) {},
    // this.$router.currentRoute
    onItemClick(event, item) {
      switch (item.href) {
        case "/":
          this.$data.currentComponent = Calculator;
          break;
        case "returns":
          this.$data.currentComponent = Calculator;
          break;
      }
      //alert("item:" + item.href )
    },
    /**
     * Handler for submit
     */
    onSubmit(evt) {
      evt.preventDefault();

      this.isSending = true;

      setTimeout(() => {
        // Build for data
        var formJSON = {};   

        for (let field in this.contact) {
          console.log(field + ":" + this.contact[field] );
          formJSON[field] = this.contact[field];
        }

        console.log("stuff: " + formJSON);

        // {name: "foo", email:"muhaye@gmail.com", message: "bla bla" }
        // "{name: \"foo\", email:\"muhaye@gmail.com\", message: \"bla bla\" }"
        // Send form to server
        this.$http
          .post("https://form.muh.mobi/prod/contact", formJSON)
          .then(response => {
            console.log(response);
            this.clearForm();
            this.isSending = false;
          })
          .catch(e => {
            console.log(e);
          });
      }, 1000);
    }

  },
  components: {
    Calculator,
    Header,
    Footer,
    Contact
  },
  data() {
    return {
      currentComponent: Calculator,
      menu: [
        {
          header: true,
          title: "Calculatrice Tps Tvq",
          hiddenOnCollapse: true,
        },
        {
          href: "/",
          title: "Montant prelevé",
          icon: "fa fa-user",
        },
        {
          href: "/returns",
          title: "Calcul des Remises",
          icon: "fa fa-chart-area",
          child: [
            {
              href: "/charts/sublink",
              title: "Méthode rapide",
            },
            {
              href: "/charts/sublink2",
              title: "Méthode simplifiée",
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss">
#app {
  font-family: 'Blinker', sans-serif;
}
</style>
