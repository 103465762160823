<template>
  <footer class="page-footer">
    <div class="container premier-footer">
      <div class="row">
        <div class="col l6 s12">
          <h5>MUH MOBILE, société par actions</h5>
          <p class="text-lighten-4">
            Nous sommes situés au bureau d'espace de travail partagé WeWork,
            <br />3 Place Ville Marie Suite 400, <br />H3B 2E3, Montréal,
            Quebec.
          </p>
        </div>
        <div class="col l4 offset-l2 s12">
          <h5>Lien</h5>
          <ul>
            <li><a href="https://www.muh.mobi/">Muh.mobi</a></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="footer-copyright">
      <div class="container copyright-text">
        © 2020 Tous droits réservés
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
.page-footer,
.footer-copyright {
  background: transparent;
  color: #3a50b3;
    text-align: center;
}

.premier-footer {
  border-top: 1px solid #3a50b3;
  padding-top: 40px;
  padding-bottom: 30px;
  margin-top: 90px;
}
.copyright-text {
  color: #3a50b3;
}
</style>
