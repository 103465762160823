<template>
  <div class="header">
    <nav class="transparent z-depth-1 nav-header">
      <div class="nav-wrapper" style="margin-left:10px">
        <img
          alt="Vue logo"
          class="brand-logo center"
          src="../assets/logo.png"
        />
        <!-- <ul class="left hide-on-med-and-down">
          <li style="margin-top:5px;">
            <button class="learn-more">
              <span class="circle" aria-hidden="true">
                <span class="icon arrow"></span>
              </span>
              <span class="button-text black-text text-lighten-2">Commencer</span>
            </button>
          </li>
        </ul> -->
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Header",
};
</script>
