<template>
  <div class="hello">
    <h1>{{ msg }}</h1>

    <h2 class="header">Calculatrice en ligne</h2>
    <div class="col s12 m7">
      <div class="card horizontal">
        <div class="card-stacked">
          <div class="card-content">
            <h4>Québec tps/tvq 2020. Normale ou Inversé</h4>
            <a
              href="https://www.revenuquebec.ca/fr/entreprises/taxes/tpstvh-et-tvq/perception-de-la-tps-et-de-la-tvq/calcul-des-taxes/"
              target="_blank"
              rel="noopener"
            >
              Cliquer ici pour déterminer les montants de la TPS et de la TVQ
              exigibles.
              <br/> CORONAVIRUS (COVID-19)
            </a>
          </div>
          <div class="card-action">
            <blockquote>
              Pour obtenir les montants de taxes veuillez entrer les valeurs
              dans les champs.<br/>
              Les calculs se font en pourcentage.
            </blockquote>
            <div class="row">
              <div class="col s12">
                <ul class="tab col s6">
                  <li class="label">Sous-Total</li>
                  <li>
                    <input
                      id="subTotal_input"
                      v-model=" netSales "
                      placeholder="Ventes Net"
                      @keyup="updateTotal"
                    />
                  </li>
                </ul>

                <ul class="tab col s6">
                  <ul>
                    <li class="label">TPS ({{ tps_ratio }}%)</li>
                    <li>
                      <input
                        pattern="^\d+(\.|\,)\d{2}$"
                        id="tps_input"
                        v-model=" tps "
                        placeholder="TPS"
                        @keyup="updateSubTotal"
                      />
                    </li>
                  </ul>
                  <ul>
                    <li class="label">TVQ ({{ tvq_ratio }}%)</li>
                    <li>
                      <input v-model=" tvq " placeholder="TVQ" @keyup="updateSubTotal" />
                    </li>
                  </ul>

                  <ul>
                    <li class="label">Total avec taxes</li>
                    <li>
                      <input
                        v-model=" revenue_101 "
                        placeholder="Total avec taxes"
                        @keyup="updateSubTotal"
                      />
                    </li>
                  </ul>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h2 class="header">Produire la Déclaration / Remboursement</h2>
    <div class="col s12 m7">
      <div class="card horizontal">
        <div class="card-stacked">
          <div class="card-content">
            <h4>Formulaire déclaration de la tps/tvh et de la tvq (fpz-500)</h4>
          </div>
          <div class="card-action">
            <form>
              <div class="row">
                <label class="pure-material-radio col s6">
                  <input
                    type="radio"
                    name="method"
                    v-model="method"
                    value="rapide"
                    v-on:change="updateFromMethod"
                  />
                  <span>Methode rapide</span>
                </label>
                <label class="pure-material-radio col s6">
                  <input
                    type="radio"
                    name="method"
                    v-model="method"
                    value="normale"
                    v-on:change="updateFromMethod"
                  />
                  <span>Normale</span>
                </label>

                <br />
              </div>
              <div v-if="method == 'rapide'" class="one_percent">
                <label class="pure-material-checkbox">
                  <input
                    id="checkbox_rapide"
                    type="checkbox"
                    v-model="onePercentCredit"
                    v-on:change="updateFromMethod"
                  />
                  <span>Appliquer Le crédit de 1%</span>
                  <br />
                </label>
                Si vous produisez des déclarations annuelles, le crédit de 1 %
                s'applique à la première tranche de {{ maxCreditTPS }}$ (pour la
                TPS) et de {{ maxCreditTVQ }}$ (pour la TVQ) des fournitures
                admissibles que vous avez effectuées au cours de cet exercice.
              </div>
            </form>

            <div class="row">
              <div class="container">
                <ul>
                  <li>
                    <b>101</b> - Total des fournitures (chiffre d’affaires)
                  </li>
                  <li>
                    <input v-model="revenue_101" readonly />
                  </li>
                </ul>
              </div>
              <div class="col s6">
                <ul>
                  <li class="label"><b>103</b> - TPS/TVH</li>
                  <li>
                    <input v-model.number="remiseTPS" type="number" />
                  </li>
                </ul>
                <ul>
                  <li class="label"><b>203</b> - TVQ</li>
                  <li>
                    <input v-model.number="remiseTVQ" type="number" />
                  </li>
                </ul>
              </div>
              <div class="col s6">
                <ul>
                  <li class="label"><b>104</b> - Redressements de TPS/TVH</li>
                  <li>
                    <input v-model.number="redressementsTPS" type="number" />
                  </li>
                </ul>
                <ul>
                  <li class="label"><b>204</b> - Redressements de TVQ</li>
                  <li>
                    <input v-model.number="redressementsTVQ" type="number" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const TPS_RATIO = 0.05;
const TVQ_RATIO = 0.09975;
const TPS_RAPIDE_RATIO = 0.036;
const TVQ_RAPIDE_RATIO = 0.066;

const TAX_RATIO = TPS_RATIO + TVQ_RATIO;

var stipNonNumber = function (inStr) {
  // replaces all non-alphanumeric characters with nothing
  const regex = /[^0-9.]/g ;
  return inStr.replace( regex, "");
};

export default {
  name: "Calculator",
  metaInfo() {
    return {
      title: "Calculatrice de taxes TPS TVQ",
      meta: [
        {
          name: "Calculateur",
          content:
            "Calculatrice pour calculer taxes TPS TVQ pour émettres de factures à vos clients.",
        },
      ],
    };
  },
  data() {
    return {
      tps_ratio: TPS_RATIO * 100,
      tvq_ratio: (TVQ_RATIO * 100).toFixed(3),
      netSales: "",
      revenue_101: "",
      tps: "",
      tvq: "",
      remiseTPS: "",
      remiseTVQ: "",
      redressementsTPS: "",
      redressementsTVQ: "",
      method: "rapide",
      onePercentCredit: false,
      maxCreditTVQ: 31421,
      maxCreditTPS: 30000,
    };
  },
  methods: {
    applyCreditIfAny: function (revenue_101, max) {
      if (!this.onePercentCredit) return 0;
      else return (revenue_101 > max ? max : revenue_101) * 0.01;
    },
    reverseTaxes: (revenue_101) => revenue_101 / (1 + TAX_RATIO),
    taxes: (netSales) => (netSales * (1 + TAX_RATIO)).toFixed(2),
    refreshTaxes: function (sTotal) {
      this.tps = (sTotal * TPS_RATIO).toFixed(2);
      this.tvq = (sTotal * TVQ_RATIO).toFixed(2);

      var tpsRatio = this.method == "rapide" ? TPS_RAPIDE_RATIO : TPS_RATIO;
      var tvqRatio = this.method == "rapide" ? TVQ_RAPIDE_RATIO : TVQ_RATIO;

      this.remiseTPS = (
        sTotal * tpsRatio -
        this.applyCreditIfAny(sTotal, this.maxCreditTPS)
      ).toFixed(2);
      this.remiseTVQ = (
        sTotal * tvqRatio -
        this.applyCreditIfAny(sTotal, this.maxCreditTVQ)
      ).toFixed(2);
    },
    updateTotal: function (event) {
      let netSalesBeforeTaxes = isNaN(event.target.value)
        ? ""
        : parseFloat(event.target.value);
      this.revenue_101 = (
        netSalesBeforeTaxes *
        (1.0 + TPS_RATIO + TVQ_RATIO)
      ).toFixed(2);
      this.netSales = stipNonNumber(event.target.value) 
      this.refreshTaxes(netSalesBeforeTaxes);
    },
    updateSubTotal: function (event) {
      
      let sT = isNaN(event.target.value) ? "" : parseFloat(event.target.value);
      this.netSales = this.reverseTaxes(sT).toFixed(2);
      this.refreshTaxes(sT);
      isNaN(sT);
    },
    updateFromMethod: function () {
      this.refreshTaxes(this.netSales);
    },
  },
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}

li.label {
  display: inline-block;
  margin: 0 10px;
  width: 120px;
}

div.one_percent {
  color: rgb(211, 136, 136);
}

input {
  text-align: right;
}

blockquote {
  border-left: 5px solid #3a50b3;
}
</style>
